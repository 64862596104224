<template>
  <v-card class="mb-2" outlined>
    <v-card-text class="py-4">
      <v-row>
        <v-col cols="12" sm="6">
          <c-autocomplete
            outlined
            rounded
            v-model="collect_channel_id"
            label="支付通道"
            :rules="[$rules.required]"
            :api="getCollectChannels"
          ></c-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            outlined
            rounded
            v-model="card_id"
            label="子帳戶"
            :rules="[$rules.required]"
            :items="cardOptions"
            :disabled="!card_id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            rounded
            v-model.number="amt"
            label="金額"
            :rules="[$rules.required]"
            type="number"
            :disabled="!card_id"
          >
            <template #append>
              <v-btn
                small
                @click="amt = Number($num.add(amt, remainAmt))"
                class="mr-2"
              >
                補齊餘額
              </v-btn>
            </template>
          </v-text-field>

          <v-btn fab small @click="$emit('delete')">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getCards } from '@/api/cards';
import { getAvailableCollectChannels } from '@/api/merchantCenter/merchants';

export default {
  props: {
    merchantId: {
      type: String,
      default: null,
      required: true
    },
    remainAmt: {
      type: [Number, String],
      default: 0,
      required: true
    },
    value: {
      type: Object,
      default: () => ({
        collect_channel_id: null,
        card_id: null,
        card_name_1: '',
        amt: 0
      })
    }
  },
  data() {
    return {
      cardOptions: [],
      cards: [],
      isFetchingCards: false,

      collect_channel_id: null,
      card_id: null,
      card_name_1: '',
      amt: 0
    };
  },

  watch: {
    collect_channel_id(v) {
      if (v) {
        this.getCards();
      } else {
        this.$set(this, 'cardOptions', []);
        this.$set(this, 'cards', []);
      }
      this.handleChange({
        collect_channel_id: v
      });
    },
    card_id(v) {
      const card = this.cards.find((c) => c.id === v);
      this.card_name_1 = card.name_1 || '';

      this.handleChange({
        card_id: card.id || null,
        card_name_1: card.name_1 || ''
      });
    },
    amt(v) {
      this.handleChange({
        amt: v
      });
    }
  },

  methods: {
    async getCollectChannels(payload) {
      return await getAvailableCollectChannels({
        merchant_id: this.merchantId,
        ...payload
      });
    },
    async getCards() {
      if (!this.collect_channel_id) return null;
      this.isFetchingCards = true;

      const Cards = await getCards({
        collect_channel_id: this.collect_channel_id,
        status: true
      });
      this.$set(this, 'cards', Cards.items);
      this.$set(
        this,
        'cardOptions',
        Cards.items.map(({ id, name_1 }) => ({
          text: name_1,
          value: id
        }))
      );

      this.$nextTick(() => {
        Cards.items.length && (this.card_id = Cards.items[0].id);
      });

      this.isFetchingCards = false;
    },
    handleChange({
      collect_channel_id = this.collect_channel_id,
      card_id = this.card_id,
      card_name_1 = this.card_name_1,
      amt = this.amt
    }) {
      this.$emit('input', {
        collect_channel_id,
        card_id,
        card_name_1,
        amt
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>