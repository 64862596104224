<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        編輯訂單
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-text-field
            v-model="form.bank_holder"
            label="收款姓名"
            rounded
            outlined
          />
          <v-text-field
            v-model="form.bank_card_no"
            label="銀行卡號"
            rounded
            outlined
          />
          <v-text-field
            v-model="form.bank_name"
            label="銀行名稱"
            rounded
            outlined
          />
          <v-text-field
            v-model="form.bank_branch"
            label="分行名稱"
            rounded
            outlined
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editCardInfoAfterPaid } from '@/api/payOrders';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({})
    },
    merchatTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      form: {
        id: '',
        bank_holder: '', // 收款姓名
        bank_card_no: '', // 銀行卡號
        bank_name: '', // 銀行名稱
        bank_branch: '' // 分行名稱
      }
    };
  },

  watch: {
    value(v) {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = this.passData[k];
      });
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'form', { ...this.passData });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const passdata = { ...this.form };

      const Merchant = await editCardInfoAfterPaid(passdata);
      if (!Merchant.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
