import { api } from '.';

export function getMerchantPayOrderList(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/payOrders/getMerchantPayOrderList', payload);
}

export function pickUpMerchantPayOrder(payload) {
  return api('/v1/payOrders/pickUpMerchantPayOrder', payload);
}

export function pickUpApiPayOrder(payload) {
  return api('/v1/payOrders/pickUpApiPayOrder', payload);
}

export function releaseMerchantPayOrder(payload) {
  return api('/v1/payOrders/releaseMerchantPayOrder', payload);
}

export function releaseApiPayOrder(payload) {
  return api('/v1/payOrders/releaseApiPayOrder', payload);
}

export function rejectMerchantPayOrder(payload) {
  return api('/v1/payOrders/rejectMerchantPayOrder', payload);
}

export function rejectApiPayOrder(payload) {
  return api('/v1/payOrders/rejectApiPayOrder', payload);
}

export function payMerchantPayOrder(payload) {
  return api('/v1/payOrders/payMerchantPayOrder', payload);
}

export function payApiPayOrder(payload) {
  return api('/v1/payOrders/payApiPayOrder', payload);
}

export function placeOrder(payload) {
  return api('/v1/payOrders/placeOrder', payload);
}

export function checkOrder(payload) {
  return api('/v1/payOrders/checkOrder', payload);
}

export function getMerchantCapital(payload) {
  return api('/v1/payOrders/getMerchantCapital', payload);
}

export function getApiPayOrderList(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/payOrders/getApiPayOrderList', payload);
}

export function sendNotify(payload) {
  return api('/v1/payOrders/sendNotify', payload);
}

export function editCardInfoAfterPaid(payload) {
  return api('/v1/payOrders/editCardInfoAfterPaid', payload);
}
