import { api } from '.';

export function addCard(payload) {
  return api('/v1/cards/addCard', payload);
}
export function editCard(payload) {
  return api('/v1/cards/editCard', payload);
}
export function editCardStatus(payload) {
  return api('/v1/cards/editCardStatus', payload);
}
export function getCard(payload) {
  return api('/v1/cards/getCard', payload);
}
export function getCards(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/cards/getCards', payload);
}
export function editCollectRule(payload) {
  return api('/v1/cards/editCollectRule', payload);
}
export function removeCard(payload) {
  return api('/v1/cards/removeCard', payload);
}
export function getCardHistory(payload) {
  return api('/v1/cards/getCardHistory', payload);
}
