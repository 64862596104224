<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        設定
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form">
          <SizeBox height="48" />
          <template v-if="passData">
            <div>總金額:{{ passData.amt }}</div>
            <div class="mb-1" :class="{ 'error--text': remainAmt !== 0 }">
              還需:{{ remainAmt }}
            </div>
          </template>
          <v-select
            rounded
            :items="settingOptions"
            v-model="settingMode"
            :rules="[$rules.required]"
            label="操作選項"
            outlined
          ></v-select>

          <v-card
            outlined
            :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
          >
            <v-card-text>
              <v-expand-transition>
                <div v-if="settingMode === 'complete'">
                  <PayCardRow
                    v-for="(card, i) in pay_cards"
                    :key="`pay_card${i}`"
                    :merchant-id="passData.merchant.id"
                    :remain-amt="remainAmt"
                    v-model="pay_cards[i]"
                    @delete="deleteRow(i)"
                    @change="(v) => v < 0 && (v = 0)"
                  />
                  <v-btn fab small @click="addRow" class="mb-3">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-expand-transition>
              <v-text-field
                rounded
                label="說明"
                v-model="note"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          :disabled="loading"
          @click="close"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          提交
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  payApiPayOrder,
  payMerchantPayOrder,
  rejectApiPayOrder,
  rejectMerchantPayOrder
} from '@/api/payOrders';
import PayCardRow from './OrderSetForm/PayCardRow.vue';

export default {
  components: { PayCardRow },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    },
    isApi: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      settingOptions: [
        { text: '結算駁回', value: 'reject' },
        { text: '付款完成', value: 'complete' }
      ],
      settingMode: null,
      note: '',
      pay_cards: [
        {
          collect_channel_id: null,
          card_id: null,
          card_name_1: '',
          amt: 0
        }
      ]
    };
  },

  computed: {
    remainAmt() {
      return this.pay_cards.reduce(
        (p, c) => ({
          amt: this.$num.subtract(p.amt, c.amt)
        }),
        {
          amt: this.passData.amt
        }
      ).amt;
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      //
    },
    reset() {
      this.settingMode = null;
      this.note = '';
      this.loading = false;
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
    },
    close() {
      this.$emit('close');
    },
    addRow() {
      this.pay_cards.push({});
    },
    deleteRow(i) {
      this.pay_cards.splice(i, 1);
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      if (
        this.settingMode === 'complete' &&
        this.$num.compareTo(this.remainAmt, 0)
      ) {
        this.$toast.error('金額不符');
        return;
      }
      this.loading = true;
      const passData = {
        id: this.passData.id,
        note: this.note,
        paid_cards: this.pay_cards
      };

      let firedApi = null;
      if (this.settingMode === 'reject') {
        firedApi = this.isApi ? rejectApiPayOrder : rejectMerchantPayOrder;
      } else if (this.settingMode === 'complete') {
        firedApi = this.isApi ? payApiPayOrder : payMerchantPayOrder;
      }

      const { error, success } = await firedApi(passData);

      if (!error || success) {
        this.$toast('提交成功');
        this.$emit('save');
        this.close();
      } else {
        this.$toast.error(error.message || '尚未撿單，提交失敗！');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>