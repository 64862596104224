<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        代付資料
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <SizeBox height="24"></SizeBox>

      <v-card-text v-if="passData">
        <v-card
          outlined
          :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
        >
          <v-card-title class="pos-r text-Heading6 OnSurface050">
            銀行資訊
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="d-flex">
              <span class="OnSurface500--text text-Body mr-4">收款姓名</span>
              <span class="OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_holder }}
              </span>
              <v-spacer></v-spacer>
              <v-btn small @click="doCopy(passData.bank_holder)">複製</v-btn>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex">
              <span class="OnSurface500--text text-Body mr-4">銀行卡號</span>
              <span class="OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_card_no }}
              </span>
              <v-spacer></v-spacer>
              <v-btn small @click="doCopy(passData.bank_card_no)">複製</v-btn>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex">
              <span class="OnSurface500--text text-Body mr-4">銀行名稱</span>
              <span class="OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_name }}
              </span>
              <v-spacer></v-spacer>
              <v-btn small @click="doCopy(passData.bank_name)">複製</v-btn>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex">
              <span class="OnSurface500--text text-Body mr-4">分行名稱</span>
              <span class="OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_branch }}
              </span>
              <v-spacer></v-spacer>
              <v-btn small @click="doCopy(passData.bank_branch)">複製</v-btn>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex">
              <span class="OnSurface500--text text-Body mr-4">申請金額</span>
              <span class="OnSurface900--text text-DisplayExtraSmall">
                {{ passData.amt | currency }}
              </span>
              <v-spacer></v-spacer>
              <v-btn small @click="doCopy(passData.amt)">複製</v-btn>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="flex-center">
        <v-btn
          rounded
          color="primary"
          depressed
          width="320"
          height="40"
          @click="isViewCardHistory = true"
        >
          查看歷史紀錄
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isViewCardHistory" scrollable>
      <v-card>
        <v-card-title>歷史紀錄</v-card-title>

        <v-card-text>
          <DataTable
            :fields="cardHistory.header"
            :list="cardHistory.items"
            :total="cardHistory.total"
            :loading="cardHistory.loading"
            :replace="['created_at|formatTime']"
            @get-list="checkHistory"
          ></DataTable>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { getCardHistory } from '@/api/cards';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({})
    }
    // routerName: {
    //   type: String,
    //   required: true
    // }
  },

  data() {
    return {
      isViewCardHistory: false,
      cardHistory: {
        loading: false,
        header: [
          { text: '卡號', value: 'bank_card_no' },
          { text: '銀行名稱', value: 'bank_name' },
          { text: '銀行分行', value: 'bank_branch' },
          { text: '銀行帳戶', value: 'bank_holder' },
          { text: '申請金額', value: 'amt' },
          { text: '建立日期', value: 'created_at' }
        ],
        page: 1,
        total: 0,
        limit: 20,
        items: []
      },
      localData: {}
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    isViewCardHistory(v) {
      if (v) {
        this.checkHistory();
      }
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...this.passData });
    },
    reset() {
      this.$set(this, 'localData', {});
    },
    close() {
      this.$emit('close');
    },
    async checkHistory() {
      const History = await getCardHistory({
        card_info: this.passData.bank_card_no,
        type: 'pay'
      });

      if (!History.error) {
        this.cardHistory.total = History.total;
        this.cardHistory.page = History.page;
        this.$set(this.cardHistory, 'items', History.items);
      }
    },
    async doCopy(v) {
      const { error } = await this.$copy(v);

      if (!error) {
        this.$toast(this.$t('COPIED'));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
