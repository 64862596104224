import { api } from '.';

export function getCollectChannelTypes() {
  return api('/v1/collectChannelTypes/getCollectChannelTypes');
}

export function addCollectChannel(payload) {
  return api('/v1/collectChannels/addCollectChannel', payload);
}

export function editCollectChannel(payload) {
  return api('/v1/collectChannels/editCollectChannel', payload);
}

export function editCollectChannelStatus(payload) {
  return api('/v1/collectChannels/editCollectChannelStatus', payload);
}

export function editCollectChannelIsAvoidDuplicate(payload) {
  return api('/v1/collectChannels/editCollectChannelIsAvoidDuplicate', payload);
}

export function removeCollectChannel(payload) {
  return api('/v1/collectChannels/removeCollectChannel', payload);
}

export function getCollectChannel(payload) {
  return api('/v1/collectChannels/getCollectChannel', payload);
}

export function getCollectChannels(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/collectChannels/getCollectChannels', payload);
}

export function editCollectRule(payload) {
  return api('/v1/collectChannels/editCollectRule', payload);
}

export function editFeePercent(payload) {
  return api('/v1/collectChannels/editFeePercent', payload);
}
